import { useEffect } from "react"

const ContactRedirect = () => {
  useEffect(()=>{
    window.location.href = "mailto:support@sheetplanner.com?subject=SheetPlanner%20help";

    // Timeout redirect to force Safari to show the "security" modal
    setTimeout(()=>{
      window.location.href = window.location.origin
    }, 0)
  }, [])

  return null
}

export default ContactRedirect
